import moment from 'moment/moment';
import React, { useEffect, useState } from 'react'
import { API_URL, WEB_URL } from './config';
import "./styles.scss";
// import "./stylesv2.scss";
import { useLocation } from 'react-router-dom';

const DeployAPI = () => {
    const [listOfApi, setListApi] = useState([]);
    const [docRecords, setRecords] = useState([]);
    const [listOfApiEnv, setListApiEnv] = useState([]);
    const [proxyInfo, setProxyInfo] = useState({});
    const [publisherName, setPublisherName] = useState(null);
    const [proxyName, setProxyName] = useState('');
    const [env, setEnv] = useState('');
    const [rev, setRevision] = useState('');
    const [revisionList, setRevisionList] = useState([]);
    const [proxyDetails, setProxyDetails] = useState(null);
    const [enablePro, setEnablePro] = useState(false);
    const [queryPresence, setQP] = useState(false);
    const [noProxies, setNoProxies] = useState(false);
    const [publisher, setPublishers] = useState([]);

    const search = useLocation().search
    const searchParams = new URLSearchParams(search)
    const publisherQP = searchParams.get('name') || '';
    const userType = searchParams.get('userType') || '';

    useEffect(() => {
        if (publisherQP.length > 0 || userType.length > 0 && publisher.length > 0) {
            setPublisherName(publisherQP)
            setQP(userType !== "admin" && publisherQP.length !== 0)
        }
    }, [publisherQP, userType, publisher])


    useEffect(() => {
        var requestOptions = { method: 'GET', redirect: 'follow' };
        const URL_BS = API_URL.FETCH_PUBLISHER_LIST;
        const URL_BESTC = API_URL.FETCH_PUBLISHER_LIST_BESTC;
        const URL_INT = API_URL.FETCH_PUBLISHER_LIST_INT;
        console.log("URL_INT", URL_INT);
        fetch(URL_BS, requestOptions)
            .then(response => response.text())
            .then(result => {
                if (JSON.parse(result)?.publishers)
                    setPublishers(JSON.parse(result)?.publishers)
            })
            .catch(error => console.log('error', error));
    }, [])

    useEffect(() => {
        if (publisherName && publisherName.length > 0 && publisher.length > 0) {
            var requestOptions = { method: 'GET', redirect: 'follow' };

            fetch(API_URL.GET_PROXIES_LIST, requestOptions)
                .then(response => response.text())
                .then(result => {
                    if (JSON.parse(result)?.proxies) {
                        // let publisherList = publisherName.split(/[ -]+/).map(v => v.toLowerCase());
                        setPublisherName(publisherName)

                        // publisher.map((i) => {
                        //     if (i.split(/[ -]+/).map(v => v.toLowerCase()).includes(publisherList[0])) {
                        //     }
                        // })
                        let filtered = JSON.parse(result)?.proxies.filter(item => {
                            // let proxyList = item.name.split(/[ -]+/).map(v => v.toLowerCase());
                            if (item.name.substr(0, publisherName.length) === publisherName) {
                                return item
                            }
                        })
                        setNoProxies(filtered.length == 0)
                        setListApi(filtered)
                        setProxyName('')
                        setProxyDetails(null)
                        setEnablePro(false)
                    }
                })
                .catch(error => console.log('error', error));

            fetch(API_URL.GET_ENV_LIST, requestOptions)
                .then(response => response.text())
                .then(result => {
                    if (JSON.parse(result)) {
                        setListApiEnv(JSON.parse(result))
                        setEnv(JSON.parse(result)[0])
                    }
                })
                .catch(error => console.log('error', error));
        }

    }, [publisherName, publisher])

    useEffect(() => {
        if (rev.length > 0) {
            var requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            fetch(`${API_URL.GET_PROXIES_LIST}/${proxyName}/${rev}`, requestOptions)
                .then(response => response.text())
                .then(result => {
                    setEnablePro(true)
                    setProxyInfo(JSON.parse(result))
                })
                .catch(error => console.log('error', error));
        }
    }, [rev])

    const fetchProxyDetails = (proxyInput) => {
        setProxyName(proxyInput)
        if (proxyInput.length > 0) {
            var requestOptions = { method: 'GET', redirect: 'follow' };

            fetch(`${API_URL.GET_PROXIES_LIST}/${proxyInput}`, requestOptions)
                .then(response => response.text())
                .then(result => {
                    if (JSON.parse(result)) {
                        setProxyDetails(JSON.parse(result))
                    }
                })
                .catch(error => console.log('error', error));
        }
    }

    console.log("proxyInfo", proxyInfo);

    const modifyProxy = () => {
        const targetEndPoints = proxyInfo?.targetEndpoints || [];
        const basepathsEndpoints = proxyInfo?.basepaths || [];
        window.location.href = `${WEB_URL}/update-api?name=${proxyDetails?.name}&description=${proxyInfo?.description}&basepaths=${JSON.stringify(basepathsEndpoints[0] === "default" ? "" : basepathsEndpoints)}&targets=${JSON.stringify(targetEndPoints[0] === "default" ? "" : targetEndPoints)}`;
    }

    const DeployProxy = () => {
        if (`${proxyDetails?.name}`.length > 0 && `${env}`.length > 0 && `${rev}`.length > 0) {

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "apiproxyname": proxyDetails?.name,
                "environment": env,
                "revision": rev
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(API_URL.DEPLOY_API_PROXY, requestOptions)
                .then(response => response.text())
                .then(result => {
                    if (JSON.parse(result)?.error) {
                        alert(JSON.parse(result)?.error.message)
                    } else if (JSON.parse(result)) {
                        alert("The request for deployment has been accepted successfully.")
                    }
                })
                .catch(error => alert("Deployment failed!"));
        }
        else {
            alert("Please fill all the fields")
        }
    }

    useEffect(() => {
        const arrList = proxyDetails?.revision || [];
        const revisionList = arrList.sort(function (a, b) { return b - a });
        setRevisionList(revisionList)
        if (revisionList.length > 0)
            setRevision(revisionList[0])
    }, [proxyDetails?.revision])

    const { description, basepaths, entityMetaDataAsProperties } = proxyInfo;
    const { createdAt, lastModifiedAt } = entityMetaDataAsProperties || {};

    const getDocument = () => {
        if (proxyDetails?.name) {
            var requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            fetch(`https://apimarketplaceapis.sandboxing.tech/getapidocs?apiproxyname=${proxyDetails?.name}`, requestOptions)
                .then(response => response.text())
                .then(result => {
                    if (JSON.parse(result)?.filepath_url) {
                        setRecords([JSON.parse(result)])
                    }
                })
                .catch(error => console.log('error', error));
        } else {
            alert("No records found")
        }
    }

    return (
        <div className='custom-margin'>
            <div className='section layout-style'>
                <label style={{ display: "inline" }} className="fetch-title one" for="publisher">Publisher</label>
                <div className='two'>
                    <select value={publisherName} className='selection-style' style={{ display: "inline" }} disabled={queryPresence} name="publisher" id="publisher" onChange={(e) => setPublisherName(e.target.value)}>
                        <option className='option-style' value="">select</option>
                        {publisher.length > 0 && (publisher.sort().map((item) => <option className='option-style' value={item}>{item}</option>))}
                    </select>
                </div>
            </div>
            <div className='section layout-style'>
                {listOfApi.length > 0 ?
                    <div className='cards'>
                        {listOfApi.map((item, index) =>
                            <div className="card-style" ckey={index} value={item.name} onClick={() => fetchProxyDetails(item.name)}>{item.name}</div>
                        )}
                    </div>
                    :
                    (noProxies && <label className="fetch-title danger">No proxies available from this publisher <span className='custom_color'>{publisherName}</span></label>)}
            </div>
            <div>
                {proxyDetails &&
                    <div>
                        {/* <div className='section layout-style'>
                            <label className="fetch-title one">Proxy Name</label >
                            <div className='two'>
                                <h4 className="fetch-title">{proxyDetails?.name}</h4>
                            </div>
                        </div> */}
                        <div className='section layout-style'>
                            <label className="fetch-title one">Revision</label>
                            <div className='two'>
                                <select className='selection-style' name="publisher" id="publisher" value={rev} onChange={(e) => setRevision(e.target.value)}>
                                    <option value="">select</option>
                                    {proxyDetails?.revision && proxyDetails?.revision.length > 0 && <>
                                        {revisionList.map((item, index) =>
                                            <option className='option-style' key={index} value={item}>{item}</option>
                                        )}
                                    </>}
                                </select>&nbsp; &nbsp; &nbsp;
                                <button className='add-button' onClick={() => modifyProxy()}>Modify Proxy</button>
                            </div>
                        </div>
                        <div className='section layout-style'>
                            <label className="fetch-title one">Environment</label>
                            <div className='two'>
                                <select className='selection-style' name="publisher" id="publisher" value={env} onChange={(e) => setEnv(e.target.value)}>
                                    <option value="">select</option>
                                    {listOfApiEnv && listOfApiEnv.length > 0 && <>
                                        {listOfApiEnv.map((item, index) =>
                                            <option className='option-style' key={index} value={item}>{item}</option>
                                        )}
                                    </>}
                                </select>&nbsp; &nbsp; &nbsp;
                                <button className='add-button' onClick={() => DeployProxy()}>Deploy Proxy</button>&nbsp; &nbsp;
                            </div>
                        </div>
                        {enablePro &&
                            <div>
                                <div className='section layout-style'>
                                    <div className='fetch-title one'><label>Description</label></div>
                                    <div className='fetch-title1 two'><b className='b_style'>: {description}</b></div>
                                </div>
                                <div className='section layout-style'>
                                    <div className='fetch-title one'><label>Basepaths</label></div>
                                    <div className='fetch-title1 two'><b className='b_style'>: {basepaths}</b></div>
                                </div>
                                <div className='section layout-style'>
                                    <div className='fetch-title one'><label>CreatedAt</label></div>
                                    <div className='fetch-title1 two'><b className='b_style'>: {moment.unix(createdAt / 1000).format("DD MMM YYYY hh:mm A")}</b></div>
                                </div>
                                <div className='section layout-style'>
                                    <div className='fetch-title one'><label>LastModifiedAt</label></div>
                                    <div className='fetch-title1 two'><b className='b_style'>: {moment.unix(lastModifiedAt / 1000).format("DD MMM YYYY hh:mm A")}</b></div>
                                </div>
                                <div className='section layout-style mt'>
                                    <button className='add-button' style={{ width: '400px' }} onClick={() => getDocument()}>Fetch API specification Doc</button>
                                </div>
                            </div>}
                        {docRecords.length > 0 && (docRecords.map(item =>
                            <div>
                                <div className='section layout-style'>
                                    <div className='fetch-title one'><label>Document format</label></div>
                                    <div className='fetch-title1 two'><b className='b_style'>: {item.doc_format}</b></div>
                                </div>
                                <div className='section layout-style'>
                                    <div className='fetch-title one'><label>File name</label></div>
                                    <div className='fetch-title1 two'><b className='b_style'>: {item.filename}</b></div>
                                </div>
                                <div className='section layout-style'>
                                    <div className='fetch-title one'><label>CreatedAt</label></div>
                                    <div className='fetch-title1 two'><b className='b_style'>: {moment.unix(new Date(item.created_at) / 1000).format("DD MMM YYYY hh:mm A")}</b></div>
                                </div>

                                <div className='section layout-style'>
                                    <div className='fetch-title one'><label>Uploaded by</label></div>
                                    <div className='fetch-title1 two'><b className='b_style'>: {item.uploaded_by}</b></div>
                                </div>
                            </div>)
                        )}
                    </div>
                }
            </div>

        </div>
    )
}

export default DeployAPI;