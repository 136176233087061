import React, { useEffect, useState } from 'react'
import { getForm } from './getForm';
import moment from 'moment/moment';
import { API_URL } from './config';

const ModifyForm = () => {
    const [proxiesOption, setProxies] = useState([]);
    const [listOfProxy, setListOfProxies] = useState([]);
    const [visibleInfo, setVisibleInfo] = useState(false);
    const [productId, setProductId] = useState('');
    const [formInputs, setForm] = useState({
        publisherName: '',
        proxies: [],
        image_urls: [],
        productid: '',
        name: '',
        description: '',
        billingPeriodDays: '30',
        quotaAmount: '',
        onetimeSetupFee: '0.00',
        recurringFee: '0.00',
        ratePlanId: [],
        currency: 'USD',
        publisherPer: '0.00',
        agentPer: '0.00',
        hostPer: '0.00',
        hostingFeePM: '0.00',
        countries: [],
        category: '',
        industry_categories: [],
        startDate: moment().format("YYYY-MM-DDThh:mm"),
        endDate: '2034-12-31T23:59',
        showInBucket: 'True',
        chargingCharacteristics: '',
        teleserviceCode: '',
        bearerServiceCode: '',
        serviceType: '',
        serviceId: '',

    })
    const {
        publisherName, proxies, productid, name, description, billingPeriodDays,
        quotaAmount, onetimeSetupFee, recurringFee, ratePlanId, currency, publisherPer, agentPer, hostPer, hostingFeePM, countries,
        category, industry_categories, startDate, endDate, chargingCharacteristics, teleserviceCode, bearerServiceCode, serviceType, serviceId
    } = formInputs;

    useEffect(() => {
        var requestOptions = { method: 'GET', redirect: 'follow' };

        fetch(API_URL.GET_PROXIES_LIST, requestOptions)
            .then(response => response.text())
            .then(result => {
                let arrVal = [], proxies = JSON.parse(result)?.proxies || [];
                if (proxies && proxies.length > 0) {
                    proxies.map((i) => arrVal.push(i.name))
                }
                setListOfProxies(arrVal)
            })
            .catch(error => console.log('error', error));
    }, [])

    const setInput = (e) => {
        if (e.target.name === "image_urls") {
            let arrFile = [];
            Array.from(e.target.files).forEach(file => arrFile.push(file.name));
            setForm({ ...formInputs, [e.target.name]: arrFile })
        } else if (e.target.name === "countries" || e.target.name === "ratePlanId" || e.target.name === "proxies" || e.target.name === "industry_categories") {
            let arrValue = formInputs[e.target.name];
            if (arrValue.includes(e.target.value)) {
                arrValue = arrValue.filter(i => i !== e.target.value)
            } else {
                arrValue.push(e.target.value)
            }
            setForm({ ...formInputs, [e.target.name]: arrValue })
        } else {
            setForm({ ...formInputs, [e.target.name]: e.target.value })
        }
    }

    const onSubmit = () => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify(getForm(formInputs));

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(API_URL.MODIFY_PRODUCT, requestOptions)
            .then(response => response.text())
            .then(result => {
                if (result?.detail) {
                    alert(result?.detail)
                } else {
                    alert("Updated successfully!")
                }
            })
            .catch(error => alert("Error: Unable to update!"));
    }

    const fetchProductInfo = () => {
        if (productId.length > 0) {
            var requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            fetch(`https://apimarketplaceapis.sandboxing.tech/get_apiproduct1?productid=${productId}`, requestOptions)
                .then(response => response.text())
                .then(result => {
                    const res = JSON.parse(result) || [];
                    if (res.length > 0) {
                        let product = res[0]
                        if (product?.publisher?.name) {
                            setVisibleInfo(true)
                            setProxies(listOfProxy.filter(i => i.substring(0, 3) === product?.publisher?.name.substring(0, 3)))
                        }
                        setForm({
                            publisherName: product?.publisher?.name || '',
                            proxies: product?.apigee_config?.proxies || [],
                            image_urls: product?.image_urls || [],
                            productid: product?.productid || '',
                            name: product?.name || '',
                            description: product?.description || '',
                            billingPeriodDays: product?.billing_period_days || '30',
                            quotaAmount: product?.apigee_config?.quota || '',
                            onetimeSetupFee: product?.price["onetime-setupfee"] || '0.00',
                            recurringFee: product?.price["recurring-fee"] || '0.00',
                            ratePlanId: product?.price["rate-planid"] || [],
                            currency: product?.price?.currency || 'USD',
                            publisherPer: product?.settlement["publisher_per"] || '0.00',
                            agentPer: product?.settlement["agent_per"] || '0.00',
                            hostPer: product?.settlement["host_per"] || '0.00',
                            hostingFeePM: product?.settlement["hosting_fee_pm"] || '0.00',
                            countries: product?.countries || [],
                            category: product?.category || '',
                            industry_categories: product["industry_category"] || [],
                            startDate: product.startdate || moment().format("YYYY-MM-DDThh:mm"),
                            endDate: product.enddate || '2034-12-31T23:59:59',
                            showInBucket: 'True',
                            chargingCharacteristics: product?.cdrconversion_mapping["charging_characteristics"] || '',
                            teleserviceCode: product?.cdrconversion_mapping["teleservice_code"] || '',
                            bearerServiceCode: product?.cdrconversion_mapping["bearer_service_code"] || '',
                            serviceType: product?.cdrconversion_mapping["service_type"] || '',
                            serviceId: product?.cdrconversion_mapping["service_id"] || '',
                        })
                    } else {
                        alert("Product id not available!")
                        setVisibleInfo(false)
                    }
                })
                .catch(error => console.log('error', error));
        } else {
            alert("Product id should not be empty!")
        }
    }

    // const updateProxyList = () => {
    //     if (publisherName.length > 0) {
    //         setProxies(listOfProxy.filter(i => i.substring(0, 3) === publisherName.substring(0, 3)))
    //     } else {
    //         alert('select the publisher name')
    //     }
    // }
    return (
        <React.Fragment>
            <label className='main-title'>Update API Product</label>
            <div className='section layout-style'>
                <label className='fetch-sub-title one'>Product ID</label>
                <div className='two'>
                    <input className='input-style' type="text" onChange={(e) => setProductId(e.target.value)} />&nbsp;&nbsp;
                    <button className='add-button' onClick={() => fetchProductInfo()}>Fetch Product</button>
                </div>
            </div>
            {visibleInfo &&
                <div>
                    <div className='section layout-style'>
                        <label className='fetch-sub-title one' for="fetch-sub-title one">Publisher Name<span class="required-field">*</span></label>
                        <div className='two'>
                            <label className='fetch-sub-title'>{publisherName}</label>
                            {/* <select className='selection-style' id="publisherName" name="publisherName" value={publisherName} onChange={(e) => setInput(e)} required>
                            <option className='option-style' value="" selected>select</option>
                            <option className='option-style' value="twilio" selected>twilio</option>
                            <option className='option-style' value="comviva">comviva</option>
                            <option className='option-style' value="stc">stc</option>
                            <option className='option-style' value="mocktel">mocktel</option>
                        </select> &nbsp;&nbsp;
                        <button className='add-button' type="button" id="fetchProxies" onClick={() => updateProxyList()}>Fetch API Proxies</button> */}
                        </div>
                    </div>
                    <div>
                        {proxiesOption.length > 0 &&
                            <div className='section layout-style'>
                                <label className='fetch-sub-title one' for="proxies">Proxies<span class="required-field">*</span></label>
                                <div className='two'>
                                    <label className='fetch-sub-title' for="proxies">{proxies}</label>
                                    {/* <select id="proxies" name="proxies" value={proxies} onChange={(e) => setInput(e)} multiple="multiple" required>
                                    <option value="" selected>select</option>
                                    {proxiesOption.map((item, i) => <option key={i} value={item}>{item}</option>)}
                                </select> */}
                                </div>
                            </div>
                        }
                    </div>
                    {/* <div className='section layout-style'>
                    <label className='fetch-sub-title one' for="productid">Product ID<span class="required-field">*</span></label>
                    <div className='two'>
                        <input className='input-style' style={{ textTransform: "uppercase" }} type="text" id="productid" name="productid" value={productid} onChange={(e) => setInput(e)} pattern="[A-Za-z0-9]+" required oninput="this.value = this.value.toUpperCase()" />
                    </div>
                </div> */}
                    <div className='section layout-style'>
                        <label className='fetch-sub-title one' for="name">Name<span class="required-field">*</span></label>
                        <div className='two'>
                            <input className='input-style' type="text" id="name" name="name" value={name} onChange={(e) => setInput(e)} required />
                        </div>
                    </div>
                    <div className='section layout-style'>
                        <label className='fetch-sub-title one' for="description">API Description</label>
                        <div className='two'>
                            <textarea className='custom-text-area-style' id="description" name="description" value={description} onChange={(e) => setInput(e)} rows="4" cols="50"></textarea>
                        </div>
                    </div>
                    <div className='section layout-style'>
                        <label className='fetch-sub-title one' for="imageUpload">Upload Images</label>
                        <div className='two'>
                            <input type="file" id="imageUpload" name="image_urls" onChange={(e) => setInput(e)} accept=".jpg, .jpeg, .png" multiple />
                        </div>
                    </div>
                    <div className='section layout-style'>
                        <label className='fetch-sub-title one' for="billingPeriodDays">Billing Period (Days):</label>
                        <div className='two'>
                            <input className='input-style' type="text" id="billingPeriodDays" name="billingPeriodDays" onChange={(e) => setInput(e)} value={billingPeriodDays} />
                        </div>
                    </div>

                    <div className='section layout-style'>
                        <label className='fetch-sub-title one' for="showInBucket">Quota: Free Hits per Month</label>
                        <div className='two'>
                            <input className='input-style' type="text" id="quota-amount" name="quotaAmount" onChange={(e) => setInput(e)} value={quotaAmount} />
                        </div>
                    </div>
                    <div className='section layout-style'>
                        <label className='fetch-sub-title one' for="onetimeSetupFee">One-time Setup Fee<span class="required-field">*</span>:</label>
                        <div className='two'>
                            <input className='input-style' type="text" id="onetimeSetupFee" name="onetimeSetupFee" onChange={(e) => setInput(e)} value={onetimeSetupFee} required />
                        </div>
                    </div>
                    <div className='section layout-style'>
                        <label className='fetch-sub-title one' for="recurringFee">Recurring Fee<span class="required-field">*</span>:</label>
                        <div className='two'>
                            <input className='input-style' type="text" id="recurringFee" name="recurringFee" value={recurringFee} onChange={(e) => setInput(e)} required />
                        </div>
                    </div>
                    <div className='section layout-style'>
                        <label className='fetch-sub-title one' for="ratePlanId">Rate Plan ID<span class="required-field">*</span>:</label>
                        <div className='two'>
                            <select id="ratePlanId" name="ratePlanId" value={ratePlanId} onChange={(e) => setInput(e)} multiple="multiple" required>
                                <option value="" selected>select</option>
                                <option value="RP1">RP1</option>
                                <option value="RP2">RP2</option>
                                <option value="RP3">RP3</option>
                                <option value="NewRP">NewRP</option>
                                <option value="SMSINTRP">SMSINTRP</option>
                            </select>
                        </div>
                    </div>
                    <div className='section layout-style'>
                        <label className='fetch-sub-title one' for="currency">Currency<span class="required-field">*</span>:</label>
                        <div className='two'>
                            <input className='input-style' type="text" id="currency" name="currency" value={currency} onChange={(e) => setInput(e)} required />
                        </div>
                    </div>

                    <div className='section layout-style'>
                        <label className='fetch-sub-title one' for="publisherPer">Publisher Percentage<span class="required-field">*</span>:</label>
                        <div className='two'>
                            <input className='input-style' type="text" id="publisherPer" name="publisherPer" value={publisherPer} onChange={(e) => setInput(e)} required />
                        </div>
                    </div>
                    <div className='section layout-style'>
                        <label className='fetch-sub-title one' for="agentPer">Agent Percentage<span class="required-field">*</span>:</label>
                        <div className='two'>
                            <input className='input-style' type="text" id="agentPer" name="agentPer" value={agentPer} onChange={(e) => setInput(e)} required />
                        </div>
                    </div>
                    <div className='section layout-style'>
                        <label className='fetch-sub-title one' for="hostPer">Host Percentage<span class="required-field">*</span>:</label>
                        <div className='two'>
                            <input className='input-style' type="text" id="hostPer" name="hostPer" value={hostPer} onChange={(e) => setInput(e)} required />
                        </div>
                    </div>
                    <div className='section layout-style'>
                        <label className='fetch-sub-title one' for="hostingFeePM">Hosting Fee PM<span class="required-field">*</span>:</label>
                        <div className='two'>
                            <input className='input-style' type="text" id="hostingFeePM" name="hostingFeePM" value={hostingFeePM} onChange={(e) => setInput(e)} required />
                        </div>
                    </div>
                    <div className='section layout-style'>
                        <label className='fetch-sub-title one' for="countries">Countries<span class="required-field">*</span>:</label>
                        <div className='two'>
                            <select id="countries" name="countries" value={countries} onChange={(e) => setInput(e)} multiple="multiple" required>
                                <option value="IN" selected>IN</option>
                                <option value="US" selected>US</option>
                                <option value="UK">UK</option>
                                <option value="SA">SA</option>
                                <option value="DE">DE</option>
                            </select>
                        </div>
                    </div>

                    <div className='section layout-style'>
                        <label className='fetch-sub-title one' for="category">API Category<span class="required-field">*</span>:</label>
                        <div className='two'>
                            <select id="category" name="category" value={category} onChange={(e) => setInput(e)} required>
                                <option value="communicationapi">Communication API</option>
                                <option value="network">Network</option>
                                <option value="data-science">Data Science</option>
                                <option value="data-science">Finance</option>
                            </select>
                        </div>
                    </div>
                    <div className='section layout-style'>
                        <label className='fetch-sub-title one' for="category">Industry Categories<span class="required-field">*</span>:</label>
                        <div className='two'>
                            <select id="category" name="industry_categories" value={industry_categories} onChange={(e) => setInput(e)} multiple required>
                                <option value="Health">Health</option>
                                <option value="Retail">Retail</option>
                                <option value="Tourism">Tourism</option>
                            </select>
                        </div>
                    </div>
                    <div className='section layout-style'>
                        <label className='fetch-sub-title one' for="startDate">Start Date<span class="required-field">*</span>:</label>

                        <div className='two'>
                            <input className='input-style' type="datetime-local" id="startDate" name="startDate" value={startDate} onChange={(e) => setInput(e)} required />

                        </div>
                    </div>
                    <div className='section layout-style'>
                        <label className='fetch-sub-title one' for="endDate">End Date<span class="required-field">*</span>:</label>
                        <div className='two'>
                            <input className='input-style' type="datetime-local" id="endDate" name="endDate" value={endDate} defaultValue="2034-12-31T23:59" onChange={(e) => setInput(e)} required />
                        </div>
                    </div>
                    {/* <label for="showInBucket">Show in Bucket<span class="required-field">*</span>:</label>
                <input type="text" id="showInBucket" name="showInBucket" value={showInBucket} onChange={(e) => setInput(e)} required />
                <br /><br /> */}
                    <br /><br />
                    <label className='main-title'>CDR Configuration:-</label>
                    <br /><br />

                    <div className='section layout-style'>
                        <label className='fetch-sub-title one' for="showInBucket">Charging Characterics:</label>
                        <div className='two'>
                            <input className='input-style' type="text" id="chargingCharacteristics" name="chargingCharacteristics" value={chargingCharacteristics} onChange={(e) => setInput(e)} />
                        </div>
                    </div>
                    <div className='section layout-style'>
                        <label className='fetch-sub-title one' for="showInBucket">Service Code</label>
                        <div className='two'>
                            <input className='input-style' type="text" id="teleserviceCode" name="teleserviceCode" value={teleserviceCode} onChange={(e) => setInput(e)} />
                        </div>
                    </div>
                    <div className='section layout-style'>
                        <label className='fetch-sub-title one' for="showInBucket">Bearer Service Code</label>
                        <div className='two'>
                            <input className='input-style' type="text" id="bearerServiceCode" name="bearerServiceCode" value={bearerServiceCode} onChange={(e) => setInput(e)} />
                        </div>
                    </div>
                    <div className='section layout-style'>
                        <label className='fetch-sub-title one' for="showInBucket">Service Type</label>
                        <div className='two'>
                            <input className='input-style' type="text" id="serviceType" name="serviceType" value={serviceType} onChange={(e) => setInput(e)} />
                        </div>
                    </div>
                    <div className='section layout-style'>
                        <label className='fetch-sub-title one' for="showInBucket">Service ID</label>
                        <div className='two'>
                            <input className='input-style' type="text" id="serviceId" name="serviceId" value={serviceId} onChange={(e) => setInput(e)} />
                        </div>
                    </div>
                    <div className='section layout-style'>
                        <button className='add-button' type="submit" onClick={() => onSubmit()}>Submit</button>
                    </div>
                </div>}
        </React.Fragment>
    )
}

export default ModifyForm;