import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { API_URL, AUTH_METHODS, arrayOfCountries } from './config';

const ModifyAPIProxy = () => {
    const [accepted, setStatus] = useState(false);
    const [acknowledge, setAcknowledge] = useState([
        { name: "apiSpec", title: 'API Specification: ', subTitle: 'Does your API follow the OpenAPI specifications?', options: [], checked: false },
        { name: "apiDoc", title: 'Documentation: ', subTitle: 'Do you have an OpenAPI Specification (OAS) file for documentation (you will have an opportunity to upload the documentation later)?', options: [], checked: false },
        { name: "apiSecurity", title: 'Security: ', subTitle: 'Do you confirm that all your APIs are served over HTTPS with valid SSL certificates?', options: [], checked: false },
        { name: "apiRequestHeader", title: 'Request Header Information: ', subTitle: 'Do you understand that your APIs will accept the following headers for transparency and traceability purposes?', options: ["appname: The app name created by the API consumer.", "productname: The commercial name of your API product sold on our marketplace.", "developer: Represents the actual API consumer.", "environment: Indicates the traffic source (e.g., SIT, development, testing, production).", "tracetransactionid: A unique ID for tracing transactions and recon needs."], checked: false },
        { name: "apiAuth", title: 'Authentication: ', subTitle: 'While we perform authentication using API Key and API Secret, we recommend an additional level of authentication using a Bearer Token passed in the header. Please ensure you have documented this process.', options: [], checked: false },
        { name: "apiDevInfo", title: 'Developer Information: ', subTitle: 'You agree not to prompt developers to sign up outside the marketplace, and you will not engage in poaching them directly to your portal.', options: [], checked: false },
        { name: "apiCompliance", title: 'Compliance and Security: ', subTitle: 'You agree that your APIs are not malicious, spyware, viruses, or involved in abuse or fraud.', options: [], checked: false },
        { name: "apiPerformance", title: 'Performance Responsibility: ', subTitle: "It's your responsibility to ensure that performance issues faced by developers due to your backend system are addressed. In cases of consistent performance issues, we reserve the right to disable the API.", options: [], checked: false }
    ]);

    const [pubInfo, setPubInfo] = useState({
        publisherList: ["twilio", "comviva", "stc", "mocktel"],
        publisher: '',
        apiName: '',
        apiNameEnable: true,
        apiBasePath: '',
        apiTarget: '',
        apiPaths: '',
        bearerToken: '',
        httpMethod: 'GET',
        contentType: 'none',
        requestBody: '',
        response: '',
        statusCode: '',
        responseHeader: {},
        responseURL: '',
        description: '',
        techRateLimit: null,
        healthCheckURL: '',
        webhookURL: '',
        healthyThreshold: '',
        block_countries: [],
        allow_countries: [],
        projectName: '',
        authMethods: [],
        enableGeoLocation: false,
    })
    const { publisher, apiName, apiNameEnable, apiBasePath, apiTarget, description, projectName, block_countries, allow_countries, authMethods, webhookURL, enableGeoLocation, techRateLimit, healthCheckURL, healthyThreshold, healthCheckTimeout, healthCheckInterval, healthCheckSuccessCodes, responseHeader, apiPaths, bearerToken, perLimit, httpMethod, contentType, requestBody, response, statusCode, responseURL } = pubInfo;

    const sampleWebHookObject = {
        "alert_id": "123456",
        "severity": "high",
        "message": "API Response Time Exceeds Threshold",
        "timestamp": "2022-03-04T10:15:30Z",
        "proxyname": "API Server",
        "healthcheck_endpoint": " http://google.com/healthcheck",
        "details": {
            "times_request_failed": 1500,
            "timeout_inseconds": 60,
            "target_endpoint": "/api/v1/endpoint",
            "http_status_code": 500
        }
    }

    const search = useLocation().search
    const searchParams = new URLSearchParams(search)
    const name = searchParams.get('name');
    const desc = searchParams.get('description');
    const basept = searchParams.get('basepaths');
    const targetpt = searchParams.get('targets');
    const userType = searchParams.get('userType') || '';

    const setInput = (e) => {
        const re = /^[0-9\b]+$/;
        if (e.target.name === "healthyThreshold") {
            setPubInfo({ ...pubInfo, [e.target.name]: e.target.value })
        } else if (e.target.name === "healthCheckTimeout" || e.target.name === "healthCheckInterval" || e.target.name === "healthCheckSuccessCodes") {
            setPubInfo({ ...pubInfo, [e.target.name]: e.target.value })
        } else if (e.target.name === "projectName") {
            setPubInfo({ ...pubInfo, [e.target.name]: e.target.value })
        } else if (e.target.name === "perLimit") {
            setPubInfo({ ...pubInfo, [e.target.name]: e.target.value })
        } else if (e.target.name === "authMethods") {
            setPubInfo({ ...pubInfo, [e.target.name]: Array.from(new Set([...authMethods, e.target.value])) })
        } else if (e.target.name === "block_countries") {
            setPubInfo({ ...pubInfo, [e.target.name]: Array.from(new Set([...block_countries, e.target.value])) })
        } else if (e.target.name === "allow_countries") {
            setPubInfo({ ...pubInfo, [e.target.name]: Array.from(new Set([...allow_countries, e.target.value])) })
        } else if (e.target.name === "publisher" && `${e.target.value}`.length > 0) {
            setPubInfo({ ...pubInfo, [e.target.name]: e.target.value, apiName: '', apiNameEnable: false })
        } else if (e.target.name === "techRateLimit") {
            setPubInfo({ ...pubInfo, [e.target.name]: e.target.value })
        } else if (e.target.name === "publisher" && `${e.target.value}`.length === 0) {
            setPubInfo({ ...pubInfo, [e.target.name]: "", apiName: "", apiNameEnable: true })
        } else if (e.target.name === "contentType") {
            setPubInfo({ ...pubInfo, [e.target.name]: e.target.id })
        } else if (e.target.name !== "techRateLimit" && e.target.name !== "contentType" && e.target.name !== "publisher") {
            setPubInfo({ ...pubInfo, [e.target.name]: e.target.value })
        } else if (e.target.name === "webhookURL" || e.target.name === "healthCheckURL") {
            setPubInfo({ ...pubInfo, [e.target.name]: e.target.value })
        }
    }

    useEffect(() => {
        let nameArr = name.split('-');
        if (nameArr.length > 0) {
            setPubInfo({
                ...pubInfo,
                publisher: nameArr[0],
                apiName: `${nameArr[0]}-${nameArr.slice(1).join('-', " ")}`,
                apiNameEnable: false,
                description: desc,
                apiBasePath: JSON.parse(basept)[0],
                apiTarget: JSON.parse(targetpt)[0]
            })
        }
    }, [name, basept, targetpt, desc])

    const isNotEmpty = (value) => `${value}`.length > 0;

    const checkReachAbility = () => {
        let validAPI = false;
        if (isNotEmpty(httpMethod) && isNotEmpty(apiTarget) && isNotEmpty(apiPaths)) {
            var myHeaders = new Headers();
            // HEADER 
            if (isNotEmpty(contentType))
                myHeaders.append("Content-Type", contentType); // application/json
            // BEARER TOKEN
            if (isNotEmpty(bearerToken))
                myHeaders.append("Authorization", `Bearer ${bearerToken}`);

            var requestOptions = { method: httpMethod, headers: myHeaders, redirect: 'follow', };

            // POST METHOD
            if (httpMethod === "POST" && isNotEmpty(requestBody)) {
                var raw = JSON.stringify(JSON.parse(requestBody));
                validAPI = true;
                requestOptions = {
                    method: httpMethod,
                    headers: myHeaders,
                    redirect: 'follow',
                    body: raw
                };
            } else if (httpMethod === "POST" && !isNotEmpty(requestBody)) {
                validAPI = false;
                alert("It's not a valid request!")
            } else if (httpMethod === "GET") {
                validAPI = true;
            }
            if (validAPI) {
                const apiPath = `${apiTarget}${apiPaths}`;

                fetch(apiPath, requestOptions)
                    .then(response => response.text())
                    .then(result => {
                        let res = JSON.parse(result);
                        if (res?.name) {
                            setPubInfo({
                                ...pubInfo,
                                responseHeader: res?.headers || {},
                                responseURL: res?.url || apiPath,
                                response: JSON.stringify(res) || {},
                                statusCode: res?.status || '200'
                            })
                            alert("The API successfully retrieved it.")
                        } else {
                            setPubInfo({
                                ...pubInfo,
                                responseHeader: res?.headers || {},
                                responseURL: res?.url || apiPath,
                                response: res || {},
                                statusCode: res?.status || ''
                            })
                            alert(JSON.stringify(res))
                        }
                    })
                    .catch(err => {
                        setPubInfo({ ...pubInfo, response: {}, statusCode: 500 })
                        alert("It's not a valid request!")
                    });
            }
        } else {
            alert("It's not a valid request!")
        }
    }

    const submitProxy = () => {
        try {
            if (`${apiName}`.length > 0 && `${apiPaths}`.length > 0 && `${apiTarget}`.length > 0) {
                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");

                var raw = JSON.stringify({
                    "apiproxyname": apiName,
                    "basepath": apiPaths,
                    "description": description,
                    "targeturl": apiTarget,
                    "revision": "2",
                    "attach_monet": "false",
                    "action": "import",
                    "ratelimit_number": techRateLimit,
                    "ratelimit_freq": healthyThreshold,
                    "ratelimit_unit": perLimit,
                });

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                };

                fetch(API_URL.UPLOAD_PROXY, requestOptions)
                    .then(response => response.text())
                    .then(result => alert('Submitted successfully!'))
                    .catch(error => alert(error));
            } else {
                alert("Please enter all the fields")
            }
        } catch (error) {
            alert(error)
        }
    }

    const updateAcknowledge = (e) => {
        setAcknowledge(prev => {
            prev.forEach((item) => {
                if (item.name === e.target.name)
                    item.checked = !item.checked
            })
            return prev
        })
    }

    const checkAcknowledge = () => {
        let status = acknowledge.some(i => i.checked === true);
        if (status) {
            setStatus(status)
        } else {
            alert("Read all the questions and confirm")
        }
    }

    const removeCountryBlock = (item) => setPubInfo({ ...pubInfo, block_countries: block_countries.filter(val => val !== item) })
    const removeCountryAllow = (item) => setPubInfo({ ...pubInfo, allow_countries: allow_countries.filter(val => val !== item) })
    const removeAuthMethods = (item) => setPubInfo({ ...pubInfo, authMethods: authMethods.filter(val => val !== item) })

    return (
        <div>
            <h2 className='main-title'>Read this carefully and acknowledge</h2><br /><br />
            <table>
                {acknowledge.map((item, index) =>
                    <tr>
                        <td><b>{item.title}</b>{userType === "admin" ? item.subTitle.replace(/you |your /g, '') : item.subTitle}
                            {item.options.length > 0 &&
                                (item.options.map((subQue, idx) =>
                                    <ul>
                                        <li key={idx}>{userType === "admin" ? subQue.replace(/you |your /g, '') : subQue}</li>
                                    </ul>
                                ))}
                        </td>
                        <td><input type="checkbox" value={item.checked} defaultChecked={item.checked} name={item.name} onClick={(e) => updateAcknowledge(e)} /></td>
                    </tr>
                )}
            </table>
            <br />
            <button className='add-button' type="button" name="backButton" onClick={() => setStatus(false)}>I will come back once I am ready</button> &nbsp;&nbsp;&nbsp;
            <button className='add-button' type="button" name="readyButton" onClick={() => checkAcknowledge()}>I read all, I am ready</button>
            <br /> <br />
            <div>
                <h2 className='main-title'>API Information for Review</h2>
                <br />
                <div id="apiInfoForm">
                    <div className='section layout-style'>
                        <label className='fetch-title one' for="publisher">Select Publisher</label>
                        <div className='two'>
                            {/* <select className='selection-style' name="publisher" id="publisher" value={publisher} onChange={(e) => setInput(e)}>
                            <option className='option-style' value="">select</option>
                            <option className='option-style' value="twilio">twilio</option>
                            <option className='option-style' value="comviva">comviva</option>
                            <option className='option-style' value="stc">stc</option>
                            <option className='option-style' value="mocktel">mocktel</option>
                        </select> */}
                            <label className='fetch-title one' for="publisher">{name}</label>
                        </div>
                    </div>
                    <div className='section layout-style'>
                        <label className='fetch-title one'>Name of API</label>
                        <div className='two'>
                            <input className='input-style' type="text" name="apiName" id="apiName" value={apiName} onChange={(e) => setInput(e)} required disabled={apiNameEnable} />
                        </div>
                    </div>
                    <div className='section layout-style'>
                        <label className='fetch-title one'>BasePath of the API</label>
                        <div className='two'>
                            <input className='input-style' type="text" name="apiBasePath" id="apiBasePath" value={apiBasePath} onChange={(e) => setInput(e)} placeholder="/" required />
                            <span className='span-style' id="fullURL"> &nbsp;(Developers will access this url: https://34.96.98.10.nip.io/)</span>
                        </div>
                    </div>
                    <div className='section layout-style'>
                        <label className='fetch-title one'>Target (Existing API)</label>
                        <div className='two'>
                            <input className='input-style' type="text" name="apiTarget" id="apiTarget" value={apiTarget} onChange={(e) => setInput(e)} placeholder="https://api.example.com" required />
                        </div>
                    </div>
                    <div className='section layout-style'>
                        <label className='fetch-title one'>Description</label>
                        <div className='two'>
                            <textarea className='custom-text-area-style' name="description" id="description" value={description} onChange={(e) => setInput(e)}></textarea>
                        </div>
                    </div>
                    <div className='section layout-style'>
                        <label className='fetch-title one'>Set Spike Arrest</label>
                        <div className='two'>
                            <input className='input-style-small' name="techRateLimit" id="techRateLimit" value={techRateLimit} onChange={(e) => setInput(e)} placeholder="Eg: 1000" style={{ marginRight: "1rem" }} required />
                            <select className='custom-selection-style-custom' name="perLimit" id="perLimit" value={perLimit} onChange={(e) => setInput(e)}>
                                <option className='option-style' value="">select</option>
                                <option className='option-style' value="second">Per Second</option>
                                <option className='option-style' value="minute">Per Minute</option>
                                {/* <option className='option-style' value="Hour">Per Hour</option>
                                <option className='option-style' value="Week">Per Week</option>
                                <option className='option-style' value="Month">Per Month</option>
                                <option className='option-style' value="Year">Per Year</option> */}
                            </select>
                        </div>
                    </div>
                    <div className='section layout-style'>
                        <label className='fetch-title one'>Healtcheck url</label>
                        <div className='two'>
                            <input className='input-style' name="healthCheckURL" id="healthCheckURL" value={healthCheckURL} onChange={(e) => setInput(e)} placeholder='Eg: https://mocktel.com/healthcheck' />
                        </div>
                    </div>
                    <div className='section layout-style'>
                        <label className='fetch-title one'>Healthy threshold</label>
                        <div className='two'>
                            <input className='input-style-small' name="healthyThreshold" id="healthyThreshold" value={healthyThreshold} onChange={(e) => setInput(e)} />
                            <span className='desc-style'>(The number of consecutive health checks successes required before considering an unhealthy target healthy.)</span>
                        </div>
                    </div>
                    <div className='section layout-style'>
                        <label className='fetch-title one'>Timeout (in seconds)</label>
                        <div className='two'>
                            <input className='input-style-small' name="healthCheckTimeout" id="healthCheckTimeout" value={healthCheckTimeout} onChange={(e) => setInput(e)} />
                            <span className='desc-style'>(The amount of time, in seconds, during which no response means a failed health check)</span>
                        </div>
                    </div>
                    <div className='section layout-style'>
                        <label className='fetch-title one'>Interval (in seconds)</label>
                        <div className='two'>
                            <input className='input-style-small' name="healthCheckInterval" id="healthCheckInterval" value={healthCheckInterval} onChange={(e) => setInput(e)} />
                            <span className='desc-style'>(The approximate amount of time between health checks of an individual target )</span>
                        </div>
                    </div>
                    <div className='section layout-style'>
                        <label className='fetch-title one'>Success codes</label>
                        <div className='two'>
                            <input className='input-style-small' name="healthCheckSuccessCodes" id="healthCheckSuccessCodes" value={healthCheckSuccessCodes} onChange={(e) => setInput(e)} />
                            <span className='desc-style'>(The codes to use when checking for a successful response from a target. You can specify multiple values (for example, "200,202" max upto 5 codes))</span>
                        </div>
                    </div>

                    <div className='section layout-style'>
                        <label className='fetch-title one'>Webhook url</label>
                        <div className='two'>
                            <input className='input-style' name="webhookURL" id="webhookURL" value={webhookURL} onChange={(e) => setInput(e)} placeholder='https://mocktel.com/alert' />
                        </div>
                    </div>
                    <div className='section layout-style'>
                        <label className='fetch-title one'>Sample Webhook Post</label>
                        <div className='two'>
                            <span className='sample-obj-style'>{JSON.stringify(sampleWebHookObject)}</span>
                        </div>
                    </div>
                    <div className='section layout-style'>
                        <label className='fetch-title'>Enable geo-location screening</label>
                        <div className='two' style={{ marginLeft: "1rem" }}>
                            <input type='checkbox' checked={enableGeoLocation} onChange={() => setPubInfo({ ...pubInfo, enableGeoLocation: !enableGeoLocation })} />
                        </div>
                    </div>
                    {enableGeoLocation &&
                        <div className='table-style'>
                            <div className='section layout-style'>
                                <label className='fetch-title one' for="publisher">Block Countries</label>
                                <div className='two'>
                                    {block_countries.length > 0 && (block_countries.map((item) =>
                                        <button style={{ marginBottom: "1rem" }} className='add-button add-spacing' type="button" name="readyButton" onClick={() => removeCountryBlock(item)}>
                                            {item} <span style={{ fontWeight: 800, backgroundColor: "white", color: 'purple', borderRadius: "50%", padding: ".1rem .4rem", marginLeft: "1rem" }}>X</span>
                                        </button>
                                    ))}
                                    {block_countries.length > 0 && <br />}
                                    <select disabled={allow_countries.length > 0} className='selection-style' name="block_countries" id="block_countries" value={block_countries[block_countries.length - 1]} onChange={(e) => setInput(e)}>
                                        <option className='option-style' value="">select</option>
                                        {arrayOfCountries.length > 0 && (arrayOfCountries.map((item) => <option className='option-style' value={item}>{item}</option>))}
                                    </select>
                                </div>
                            </div>
                            <span className='location-or-style'>or</span>
                            <div className='section layout-style'>
                                <label className='fetch-title one' for="publisher">Allow Countries</label>
                                <div className='two'>
                                    {allow_countries.length > 0 && (allow_countries.map((item) =>
                                        <button style={{ marginBottom: "1rem" }} className='add-button add-spacing' type="button" name="readyButton" onClick={() => removeCountryAllow(item)}>
                                            {item} <span style={{ fontWeight: 800, backgroundColor: "white", color: 'purple', borderRadius: "50%", padding: ".1rem .4rem", marginLeft: "1rem" }}>X</span>
                                        </button>
                                    ))}
                                    {allow_countries.length > 0 && <br />}
                                    <select disabled={block_countries.length > 0} className='selection-style' name="allow_countries" id="allow_countries" value={allow_countries[allow_countries.length - 1]} onChange={(e) => setInput(e)}>
                                        <option className='option-style' value="">select</option>
                                        {arrayOfCountries.length > 0 && (arrayOfCountries.map((item) => <option className='option-style' value={item}>{item}</option>))}
                                    </select>
                                </div>
                            </div>
                        </div>}
                    <div className='section layout-style'>
                        <label className='fetch-title one' for="publisher">Auth Method</label>
                        <div className='two'>
                            {authMethods.length > 0 && (authMethods.map((item) =>
                                <button style={{ marginBottom: "1rem" }} className='add-button add-spacing' type="button" name="readyButton" onClick={() => removeAuthMethods(item)}>
                                    {item} <span style={{ fontWeight: 800, backgroundColor: "white", color: 'purple', borderRadius: "50%", padding: ".1rem .4rem", marginLeft: "1rem" }}>X</span>
                                </button>
                            ))}
                            {authMethods.length > 0 && <br />}
                            <select className='selection-style' name="authMethods" id="authMethods" value={authMethods[authMethods.length - 1]} onChange={(e) => setInput(e)}>
                                <option className='option-style' value="">select</option>
                                {AUTH_METHODS.length > 0 && (AUTH_METHODS.map((item) => <option className='option-style' value={item}>{item}</option>))}
                            </select>
                        </div>
                    </div>
                    <div className='section layout-style'>
                        <label className='fetch-title one'>Name of the project</label>
                        <div className='two'>
                            <input className='input-style-small' name="projectName" id="projectName" value={projectName} onChange={(e) => setInput(e)} />
                        </div>
                    </div>
                    <br />
                    <div className='section layout-style'>
                        <label className='fetch-title' for="apiReviewContainer"><b>For Testing Purposes:-</b></label>
                    </div>
                    <div id="apiReviewContainer" className='table-style'>
                        <div className='section layout-style'>
                            <label className='fetch-sub-title three'>API Paths(only if applicable) for testing</label>
                            <div className='four'>
                                <input className='custom-input-style' type="text" name="apiPaths" id="apiPaths" value={apiPaths} onChange={(e) => setInput(e)} />
                            </div>
                        </div>
                        <div className='section layout-style'>
                            <label className='fetch-sub-title three'>Enter Bearer Token here(this will be send in header as Authorization)</label>
                            <div className='four'>
                                <input className='custom-input-style' type="text" name="bearerToken" id="bearerToken" size="80" value={bearerToken} onChange={(e) => setInput(e)} />
                            </div>
                        </div>
                        <div className='section layout-style'>
                            <label className='fetch-sub-title three'>HTTP Method</label>
                            <div className='four'>
                                <select className='custom-selection-style' name="httpMethod" id="httpMethod" value={httpMethod} onChange={(e) => setInput(e)}>
                                    <option className='option-style' value="">select</option>
                                    <option className='option-style' value="GET">GET</option>
                                    <option className='option-style' value="POST">POST</option>
                                </select>
                            </div>
                        </div>
                        <div className='section layout-style'>
                            <label className='fetch-sub-title three'>Content Type</label>
                            <div className='four'>
                                <input type="radio" name="contentType" id="none" defaultChecked value={contentType} onClick={(e) => setInput(e)} />
                                <label className='fetch-sub-title' for="none">None</label>

                                {/* <input type="radio" name="contentType" id="multipart/form-data" value={contentType} onClick={(e) => setInput(e)} /> */}
                                {/* <label className='fetch-sub-title' for="form-data">Form Data</label>

                            <input type="radio" name="contentType" id="application/x-www-form-urlencoded" value="x-www-form-urlencoded" />
                            <label className='fetch-sub-title' for="x-www-form-urlencoded">x-www-form-urlencoded</label> */}

                                <input type="radio" name="contentType" id="application/json" value={contentType} onClick={(e) => setInput(e)} />
                                <label className='fetch-sub-title' for="json">JSON</label>

                                <input type="radio" name="contentType" id="text/plain" value={contentType} onClick={(e) => setInput(e)} />
                                <label className='fetch-sub-title' for="text">Text</label>

                                {/* <input type="radio" name="contentType" id="text/plain" value={contentType} onClick={(e) => setInput(e)} />
                            <label className='fetch-sub-title' for="binary">Binary</label> */}
                            </div>
                        </div>
                        <div className='section layout-style'>
                            <label className='fetch-sub-title three'>Request Body</label>
                            <div className='fetch-sub-title four'>
                                <textarea className='custom-text-area-style' name="requestBody" id="requestBody" value={requestBody} onChange={(e) => setInput(e)} rows="5" cols="50" ></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='section layout-style'>
                        <button className='add-button' id="checkReachability" onClick={() => checkReachAbility()} >Check Reachability</button>
                    </div>
                    <div className='section layout-style'>
                        <label className='main-title' >Response</label>
                    </div>
                    <br />
                    <table>
                        <tr>
                            <th style={{ width: "100px" }}>Response</th>
                            <th></th>
                        </tr>
                        <tr>
                            <td>Curl</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Request URL</td>
                            <td>{responseURL}</td>
                        </tr>
                        <tr>
                            <td>Server Response Code</td>
                            <td>{statusCode}</td>
                        </tr>
                        <tr>
                            <td>Response Body</td>
                            <td>{`${response}`}</td>
                        </tr>
                        <tr>
                            <td>Response Headers</td>
                            <td>{JSON.stringify(responseHeader)}</td>
                        </tr>
                    </table>
                </div>
                <div className='section layout-style'>
                    <button className='add-button' id="submitButton" onClick={() => submitProxy()}>Submit API</button>
                </div>
                <br />
                <br />
                <br />
                <br />
                <br />
            </div>
        </div >
    )
}

export default ModifyAPIProxy;