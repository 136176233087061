import React, { Fragment } from 'react'
import { Routes, Route, Link } from 'react-router-dom';
import CreateForm from './createForm';
import ListForm from './listForm';
import ModifyForm from './modifyForm';
import APIPublisher from './apiPublisher';
import DeployAPI from './deployApi';
import ModifyAPIProxy from './modifyAPIproxy';
import "./styles.scss";
// import "./stylesv2.scss";
import UploadFile from './uploadFile';

// components

export const MainPage = () => {
    const pages = [
        {
            name: "API Publisher",
            path: "/publish-api",
            element: <APIPublisher />,
            exact: true,
        },
        {
            name: "API Publisher",
            path: "/deploy-api",
            element: <DeployAPI />,
            exact: true,
        },
        {
            name: "Create Form",
            path: "/Create",
            element: <CreateForm />,
            exact: true,
        },
        {
            name: "List Forms",
            path: "/get",
            element: <ListForm />,
            exact: true,
        },
        {
            name: "Modify Form",
            path: "/update",
            element: <ModifyForm />,
            exact: true,
        },
        {
            name: "Modify API",
            path: "/update-api",
            element: <ModifyAPIProxy />,
            exact: true,
        },
        {
            name: "Upload APISepc Doc",
            path: "/Upload-APISepc-Doc",
            element: <UploadFile />,
            exact: true,
        }
    ]
    return (
        <Fragment>
            {/* <Link className="menu-style" to="/deploy-api">1. Show API Proxies</Link>&nbsp;&nbsp;&nbsp;
            <Link className="menu-style" to="/publish-api">2. Create API Proxy</Link>&nbsp;&nbsp;&nbsp;
            <Link className="menu-style" to="/Upload-APISepc-Doc">6. Upload APISepc Doc</Link>&nbsp;&nbsp;&nbsp;
            <Link className="menu-style" to="/update-api">3. Modify API Proxy</Link><br/><br/>  */}
             {/* <Link className="menu-style" to="/get">3. Show API Products</Link><br/><br/>
            <Link className="menu-style" to="/create">4. Create API Product</Link><br/><br/>
            <Link className="menu-style" to="/update">5. Modify API Product</Link><br/><br/> */}
            <Routes>
                {pages.map((item) => {
                    return (
                        <Route
                            key={item.name}
                        // element={item.public ? <Public /> : <Private showBack={item.name !== "name"} />}
                        >

                            <Route
                                path={item.path}
                                element={item.element}
                                exact={item.exact}
                                name={item.name}
                            />

                        </Route>
                    )
                })}
                <Route />
            </Routes>
        </Fragment>
    )
}