import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { API_URL } from './config';

const ListForm = () => {

    const [forms, setForms] = useState([]);

    useEffect(() => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(API_URL.LIST_API_PRODUCTS, requestOptions)
            .then(response => response.text())
            .then(result => setForms(JSON.parse(result)))
            .catch(error => console.log('error', error));
    }, [])


    const removePlan = (new_product_id) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({ "productid": new_product_id });

        var requestOptions = {
            method: 'DELETE',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(API_URL.DELETE_API_PRODUCT, requestOptions)
            .then(response => response.text())
            .then(result => {
                alert("Deleted successfully!")
                window.location.reload()
            })
            .catch(error => alert("Failure"));
    }
    return (
        <div className=''>
            {forms.length > 0 &&
                <div className='cards1'>
                    {forms.map((item) => <div className='form_layout'>
                        <div className='card1' onClick={() => removePlan(item?.productid)}><button className='add-button'>Remove</button></div>
                        <div className='section'>
                            <label className='fetch-sub-desc five'>Name</label>
                            <div className='five'><span className='fetch-sub-desc'> : {item.name}</span></div>
                        </div>
                        <div className='section'>
                            <label className='fetch-sub-desc five'>Product id</label>
                            <div className='five'><span className='fetch-sub-desc'> : {item.productid}</span></div>
                        </div>
                        <div className='section'>
                            <label className='fetch-sub-desc five'>Image URLS</label>
                            <div className='five'><span className='fetch-sub-desc'> : {item.image_urls}</span></div>
                        </div>

                        <div className='section'>
                            <label className='fetch-sub-desc five'>Billing period days</label>
                            <div className='five'><span className='fetch-sub-desc'> : {item.billing_period_days}</span></div>
                        </div>
                        <div className='section'>
                            <label className='fetch-sub-desc five'>Description</label>
                            <div className='five'><span className='fetch-sub-desc'> : {item.description}</span></div>
                        </div>
                        <div className='section'>
                            <label className='fetch-sub-desc five'>Onetime SetupFee</label>
                            <div className='five'><span className='fetch-sub-desc'> : {item?.price["onetime-setupfee"]}</span></div>
                        </div>
                        <div className='section'>
                            <label className='fetch-sub-desc five'>RecurringFee</label>
                            <div className='five'><span className='fetch-sub-desc'> : {item?.price["recurring-fee"]}</span></div>
                        </div>
                        <div className='section'>
                            <label className='fetch-sub-desc five'>RatePlanId</label>
                            <div className='five'><span className='fetch-sub-desc'> : {`${item?.price["rate-planid"]}`.replaceAll(",", " - ")}</span></div>
                        </div>
                        <div className='section'>
                            <label className='fetch-sub-desc five'>Currency</label>
                            <div className='five'><span className='fetch-sub-desc'> : {item?.price?.currency}</span></div>
                        </div>
                        <div className='section'>
                            <label className='fetch-sub-desc five'>Proxies</label>
                            <div className='five'><span className='fetch-sub-desc'> : {item?.apigee_config?.proxies}</span></div>
                        </div>
                        <div className='section'>
                            <label className='fetch-sub-desc five'>QuotaAmount</label>
                            <div className='five'><span className='fetch-sub-desc'> : {item?.apigee_config?.quota}</span></div>
                        </div>
                        <div className='section'>
                            <label className='fetch-sub-desc five'>Publisher Name</label>
                            <div className='five'><span className='fetch-sub-desc'> : {item?.publisher?.name}</span></div>
                        </div>
                        <div className='section'>
                            <label className='fetch-sub-desc five'>PublisherPer</label>
                            <div className='five'><span className='fetch-sub-desc'> : {item?.settlement?.publisherPer}</span></div>
                        </div>

                        <div className='section'>
                            <label className='fetch-sub-desc five'>AgentPer</label>
                            <div className='five'><span className='fetch-sub-desc'> : {item?.settlement?.agent_per}</span></div>
                        </div>
                        <div className='section'>
                            <label className='fetch-sub-desc five'>HostPer</label>
                            <div className='five'><span className='fetch-sub-desc'> : {item?.settlement?.host_per}</span></div>
                        </div>
                        <div className='section'>
                            <label className='fetch-sub-desc five'>HostingFeePM</label>
                            <div className='five'><span className='fetch-sub-desc'> : {item?.settlement?.hosting_fee_pm}</span></div>
                        </div>

                        <div className='section'>
                            <label className='fetch-sub-desc five'>Charging Characteristics</label>
                            <div className='five'><span className='fetch-sub-desc'> : {item?.cdrconversion_mapping?.charging_characteristics}</span></div>
                        </div>
                        <div className='section'>
                            <label className='fetch-sub-desc five'>Tele service Code</label>
                            <div className='five'><span className='fetch-sub-desc'> : {item?.cdrconversion_mapping?.teleservice_code}</span></div>
                        </div>
                        <div className='section'>
                            <label className='fetch-sub-desc five'>Bearer Service Code</label>
                            <div className='five'><span className='fetch-sub-desc'> : {item?.cdrconversion_mapping?.bearer_service_code}</span></div>
                        </div>
                        <div className='section'>
                            <label className='fetch-sub-desc five'>Service Type</label>
                            <div className='five'><span className='fetch-sub-desc'> : {item?.cdrconversion_mapping?.service_type}</span></div>
                        </div>
                        <div className='section'>
                            <label className='fetch-sub-desc five'>Service Id</label>
                            <div className='five'><span className='fetch-sub-desc'> : {item?.cdrconversion_mapping?.service_id}</span></div>
                        </div>
                        <div className='section'>
                            <label className='fetch-sub-desc five'>Countries</label>
                            <div className='five'><span className='fetch-sub-desc'> : {item?.countries}</span></div>
                        </div>
                        <div className='section'>
                            <label className='fetch-sub-desc five'>Category</label>
                            <div className='five'><span className='fetch-sub-desc'> : {item?.Category}</span></div>
                        </div>
                        <div className='section'>
                            <label className='fetch-sub-desc five'>Industry_categories</label>
                            <div className='five'><span className='fetch-sub-desc'> : {item?.industry_categories}</span></div>
                        </div>
                        <div className='section'>
                            <label className='fetch-sub-desc five'>StartDate</label>
                            <div className='five'><span className='fetch-sub-desc'> : {(moment(item.startdate).format("DD-MM-YYYThh:mm:ss"))}</span></div>
                        </div>
                        <div className='section'>
                            <label className='fetch-sub-desc five'>EndDate</label>
                            <div className='five'><span className='fetch-sub-desc'> : {(moment(item.enddate).format("DD-MM-YYYThh:mm:ss"))}</span></div>
                        </div>
                        <div className='section'>
                            <label className='fetch-sub-desc five'>Sent to messaginbroker</label>
                            <div className='five'><span className='fetch-sub-desc'> : {item.Sent_to_messaginbroker}</span></div>
                        </div>
                        <div className='section'>
                            <label className='fetch-sub-desc five'>Synced in BillingCatalog</label>
                            <div className='five'><span className='fetch-sub-desc'> : {item.Synced_in_BillingCatalog}</span></div>
                        </div>
                        <div className='section'>
                            <label className='fetch-sub-desc five'>Synced_in_APIMAnager</label>
                            <div className='five'><span className='fetch-sub-desc'> : {item.Synced_in_APIMAnager}</span></div>
                        </div>
                        <div className='section'>
                            <label className='fetch-sub-desc five'>Modifiedtimestamp</label>
                            <div className='five'><span className='fetch-sub-desc'> : {(moment(item.modifiedtimestamp).format("DD-MM-YYYThh:mm:ss"))}</span></div>
                        </div>
                    </div>)}
                </div>
            }
        </div>
    )
}

export default ListForm;