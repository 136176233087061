import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { API_URL } from "./config";

const UploadFile = () => {
    const search = useLocation().search
    const searchParams = new URLSearchParams(search)
    const publisherQP = searchParams.get('name') || '';
    const userType = searchParams.get('userType') || '';

    const [apiproxyname, setApiproxyname] = useState('');
    const [listOfProxy, setListOfProxies] = useState([]);
    const [publisher, setPublishers] = useState([]);
    const [publisherName, setPublisherName] = useState(null);

    const [fileType, setFileType] = useState({ type: "", value: "" });
    const [docType] = useState({
        "TYPE1": ['json'],
        "TYPE2": ['json', 'YAML'],
    });
    const [selectedFile, setSelectedFile] = useState(null);
    const [currentDocType, updateDocType] = useState('');
    const [currentFormat, updateCurrentFormat] = useState('');
    const [noProxies, setNoProxies] = useState(false);

    const { type } = fileType;

    useEffect(() => {
        let result = publisher.filter(i => `${i}`.toLowerCase() === `${publisherQP}`.toLowerCase())

        if (result.length > 0) {
            setPublisherName(result[0])
        }
    }, [publisherQP, publisher])


    useEffect(() => {
        var requestOptions = { method: 'GET', redirect: 'follow' };
        const URL_BS = API_URL.FETCH_PUBLISHER_LIST;
        const URL_BESTC = API_URL.FETCH_PUBLISHER_LIST_BESTC;
        const URL_INT = API_URL.FETCH_PUBLISHER_LIST_INT;
        fetch(URL_BS, requestOptions)
            .then(response => response.text())
            .then(result => {
                if (JSON.parse(result)?.publishers)
                    setPublishers(JSON.parse(result)?.publishers)
            })
            .catch(error => console.log('error', error));
    }, [])

    const onFileChange = (event) => {
        let docTypes = currentDocType.split(',');
        if (docTypes.includes(`.${event.target.files[0].name.split('.').pop()}`)) {
            setSelectedFile(event.target.files[0])
            updateCurrentFormat(event.target.files[0].name.split('.').pop())
        } else if (`${currentDocType}`.length === 0) {
            alert("Please select document type")
        } else {
            alert("File format not supported!")
            window.location.reload();
        }
    };

    const onFileUpload = () => {
        if (selectedFile) {
            const formData = new FormData();
            formData.append("file", selectedFile, selectedFile?.name);
            var requestOptions = { method: 'POST', body: formData, redirect: 'follow' };

            fetch(`https://apimarketplaceapis.sandboxing.tech/uploadapiproxydoc?apiproxyname=${apiproxyname}&doc_format=${currentFormat}&uploaded_by=TanveerMahmood&publisher=${userType}`, requestOptions, "/C:/Users/vishnu.r/Downloads/comviva-cpass.yaml")
                .then(response => response.text())
                .then(result => {
                    let apiResult = JSON.parse(result);
                    if (apiResult?.detail) {
                        alert(apiResult.detail)
                    } else {
                        alert("Uploaded successfully!")
                        window.location.reload();
                    }
                })
                .catch(error => console.log('error', error));
        } else {
            alert("Please uplaod the document")
        }
    }

    const documentTypeCheck = docType[type] || [];

    useEffect(() => {
        if (publisherName && publisherName.length > 0 && publisher.length > 0) {
            var requestOptions = { method: 'GET', redirect: 'follow' };

            fetch(API_URL.GET_PROXIES_LIST, requestOptions)
                .then(response => response.text())
                .then(result => {
                    if (JSON.parse(result)?.proxies) {
                        // let publisherList = publisherName.split(/[ -]+/).map(v => v.toLowerCase());
                        let filtered = JSON.parse(result)?.proxies.filter(item => {
                            // let proxyList = item.name.split(/[ -]+/).map(v => v.toLowerCase());
                            if (item.name.substr(0, publisherName.length) === publisherName) {
                                return item
                            }
                        })
                        setListOfProxies(filtered)
                        setNoProxies(filtered.length == 0)
                    }
                })
                .catch(error => console.log('error', error));
        }

    }, [publisherName, publisher])


    return (
        <div>
            <div className='section layout-style'>
                <label style={{ display: "inline" }} className="fetch-title one" for="publisher">Publisher</label>
                <div className='two'>
                    <select
                        value={publisherName}
                        className='selection-style'
                        style={{ display: "inline" }}
                        disabled={publisher.length > 0 && userType !== "admin"}
                        name="publisher"
                        id="publisher"
                        onChange={(e) => setPublisherName(e.target.value)}>
                        <option className='option-style' value="">select</option>
                        {publisher.length > 0 && (publisher.sort().map((item) => <option className='option-style' value={item}>{item}</option>))}
                    </select>
                </div>
            </div>
            <div className='section layout-style'>

                {listOfProxy.length > 0 ?
                    <div className='section layout-style'>
                        <label className='fetch-title one' for="proxies">Proxies<span class="required-field">*</span></label>
                        <div className='two'>
                            <select className='selection-style' id="proxies" name="proxies" onChange={(e) => setApiproxyname(e.target.value)}>
                                <option className='option-style' value="" selected>select</option>
                                {listOfProxy.map((item, i) => <option className='option-style' key={i} value={item.name}>{item.name}</option>)}
                            </select>
                        </div>
                    </div>
                    :
                    (noProxies && <label className="fetch-title danger">No proxies available from this publisher <span className='custom_color'>{publisherName}</span></label>)
                }
            </div>
            {apiproxyname.length > 0 &&
                <>
                    <div className="radio_layout">
                        <div className='section layout-style'>
                            <label className='fetch-title one' for="publisher">Select File Type</label>
                            <div className='radio_layout two'>
                                <div className="">
                                    <input type="radio" name="fileType" id="TYPE1" value={fileType.type} onClick={() => {
                                        setFileType({ type: "TYPE1", value: "Postman collection" })
                                        updateDocType('.json')
                                    }} />
                                    <label className='fetch-title one'>Postman collection</label>
                                </div>
                                <div className="">
                                    <input type="radio" name="fileType" id="TYPE2" value={fileType.type} onClick={() => {
                                        setFileType({ type: "TYPE2", value: "OAS File" })
                                        updateDocType('.yaml,.json')
                                    }} />
                                    <label className='fetch-title one'>OAS File (Version V2+)</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    {documentTypeCheck.length > 0 &&
                        <div>
                            <div className='section layout-style'>
                                <label className='fetch-title one' for="publisher">Upload Document</label>
                                <div className='two'>
                                    <input type="file" id="upload" accept={currentDocType} onChange={(e) => onFileChange(e)} hidden />
                                    <label className="custom-file-label" for="upload">{selectedFile?.name ? selectedFile?.name : "Choose file"}</label>&nbsp;&nbsp;&nbsp;
                                    <button className="add-button" onClick={onFileUpload}>Upload</button>
                                </div>
                            </div>
                        </div>
                    }
                </>
            }

        </div>
    )
}
export default UploadFile;