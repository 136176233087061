export const getForm = (formInputs) => {
    const {
        publisherName, proxies, productid, name, description, billingPeriodDays,
        quotaAmount, onetimeSetupFee, recurringFee, ratePlanId, currency, publisherPer, agentPer, hostPer, hostingFeePM, countries,
        category, industry_categories, startDate, endDate, showInBucket, chargingCharacteristics, teleserviceCode, bearerServiceCode, serviceType, image_urls, serviceId
    } = formInputs;
    return {
        "productid": productid,
        "name": name,
        "allowance": {
            "amount": 0,
            "units": "hits"
        },
        "type": "api",
        "image_urls": image_urls,
        "status": "active",
        "billing_period_days": billingPeriodDays,
        "description": description,
        "price": {
            "onetime-setupfee": onetimeSetupFee,
            "recurring-fee": recurringFee,
            "rate-planid": ratePlanId,
            "minimum-commitmemt": 0,
            "currency": currency
        },
        "apigee_config": {
            "proxies": proxies,
            "quota": quotaAmount,
            "quotaInterval": "1",
            "quotaTimeUnit": "month"
        },
        "publisher": {
            "name": publisherName,
            "agentname": "",
            "provision_inpublisher": "True",
            "callback_url_POST": "https://apibulisher.com:9011/provisioningendpoint",
            "BearerToken": "",
            "HasPublisherPortal": "True",
            "PublisherPortal_url": "https://ngagecpass.com"
        },
        "settlement": {
            "publisher_per": publisherPer,
            "agent_per": agentPer,
            "host_per": hostPer,
            "hosting_fee_pm": hostingFeePM
        },
        "cdrconversion_mapping": {
            "charging_characteristics": chargingCharacteristics,
            "teleservice_code": teleserviceCode,
            "bearer_service_code": bearerServiceCode,
            "service_type": serviceType,
            "service_id": serviceId
        },
        "countries": countries,
        "show_in_bucket": showInBucket,
        "category": category,
        "industry_category": industry_categories,
        "segments": [
            {
                "type": "postpaid"
            },
            {
                "type": "prepaid"
            }
        ],
        "customer_rating": [
            5,
            4,
            9
        ],
        "customer_reviews": [
            {
                "user": "wintheworld",
                "comments": "this is pathetic publisher"
            }
        ],
        "meta_data": {
            "tags": [
                "sms",
                "promotion",
                "cpaas"
            ],
            "category": "communication",
            "version": "1.01"
        },
        "embedded_video_url": "",
        "embedded_api_doc_url": "",
        "startdate": startDate,
        "enddate": endDate,
        "Sent_to_messaginbroker": "False",
        "Synced_in_BillingCatalog": "False",
        "Synced_in_APIMAnager": "False",
        "modifiedtimestamp": "2023-10-30T19:20:13"
    }
}