import React, { useEffect, useState } from 'react'
import { getForm } from './getForm';
import moment from 'moment/moment';
import { API_URL } from './config';

const CreateForm = () => {
    const [proxiesOption, setProxies] = useState([]);
    const [listOfProxy, setListOfProxies] = useState([]);
    const [visibleInfo, setVisibleInfo] = useState(false);
    const [formInputs, setForm] = useState({
        publisherName: '',
        proxies: [],
        image_urls: [],
        productid: '',
        name: '',
        description: '',
        billingPeriodDays: '30',
        quotaAmount: '',
        onetimeSetupFee: '0.00',
        recurringFee: '0.00',
        ratePlanId: [],
        currency: 'USD',
        publisherPer: '0.00',
        agentPer: '0.00',
        hostPer: '0.00',
        hostingFeePM: '0.00',
        countries: [],
        category: '',
        industry_categories: [],
        startDate: moment().format("YYYY-MM-DDThh:mm"),
        endDate: '2034-12-31T23:59',
        showInBucket: 'True',
        chargingCharacteristics: '',
        teleserviceCode: '',
        bearerServiceCode: '',
        serviceType: '',
        serviceId: '',

    })
    const {
        publisherName, proxies, productid, name, description, billingPeriodDays,
        quotaAmount, onetimeSetupFee, recurringFee, ratePlanId, currency, publisherPer, agentPer, hostPer, hostingFeePM, countries,
        category, industry_categories, startDate, endDate, chargingCharacteristics, teleserviceCode, bearerServiceCode, serviceType, serviceId
    } = formInputs;

    useEffect(() => {
        var requestOptions = { method: 'GET', redirect: 'follow' };

        fetch(API_URL.GET_PROXIES_LIST, requestOptions)
            .then(response => response.text())
            .then(result => {
                let arrVal = [], proxies = JSON.parse(result)?.proxies || [];
                if (proxies && proxies.length > 0) {
                    proxies.map((i) => arrVal.push(i.name))
                }
                setListOfProxies(arrVal)
            })
            .catch(error => console.log('error', error));
    }, [])

    const setInput = (e) => {
        if (e.target.name === "image_urls") {
            let arrFile = [];
            Array.from(e.target.files).forEach(file => arrFile.push(file.name));
            setForm({ ...formInputs, [e.target.name]: arrFile })
        } else if (e.target.name === "countries" || e.target.name === "ratePlanId" || e.target.name === "proxies" || e.target.name === "industry_categories") {
            let arrValue = formInputs[e.target.name];
            if (arrValue.includes(e.target.value)) {
                arrValue = arrValue.filter(i => i !== e.target.value)
            } else {
                arrValue.push(e.target.value)
            }
            setForm({ ...formInputs, [e.target.name]: arrValue })
        } else {
            setForm({ ...formInputs, [e.target.name]: e.target.value })
        }
    }

    const onSubmit = () => {
        const { productid } = formInputs;
        console.log("formInputs", formInputs);
        if (`${productid}`.length > 0 && `${name}`.length > 0) {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            var raw = JSON.stringify(getForm(formInputs));
            var requestOptions = { method: 'POST', headers: myHeaders, body: raw, redirect: 'follow' };

            fetch(API_URL.CREATE_API_PRODUCT, requestOptions)
                .then(response => response.text())
                .then(result => {
                    let res = JSON.parse(result);
                    if (res?.detail) {
                        alert(res?.detail)
                    } else {
                        alert("Created successfully!")
                    }
                })
                .catch(error => alert("Unable to create!"));
        } else {
            alert("Please fill mandatory fields!")
        }

    }

    const updateProxyList = () => {
        if (publisherName.length > 0) {
            setProxies(listOfProxy.filter(i => i.substring(0, 3) === publisherName.substring(0, 3)))
            setVisibleInfo(true)
        } else {
            alert('select the publisher name')
        }
    }
    return (
        <React.Fragment>
            <h1 className='main-title'>Create API Product</h1>
            <div>
                <div className='section layout-style'>
                    <label className='fetch-sub-title one' for="fetch-sub-title one">Publisher Name<span class="required-field">*</span></label>
                    <div className='two'>
                        <select className='selection-style' id="publisherName" name="publisherName" value={publisherName} onChange={(e) => setInput(e)} required>
                            <option className='option-style' value="" selected>select</option>
                            <option className='option-style' value="twilio" selected>twilio</option>
                            <option className='option-style' value="comviva">comviva</option>
                            <option className='option-style' value="stc">stc</option>
                            <option className='option-style' value="mocktel">mocktel</option>
                        </select> &nbsp;&nbsp;
                        <button className='add-button' type="button" id="fetchProxies" onClick={() => updateProxyList()}>Fetch API Proxies</button>
                    </div>
                </div>
                <div>
                    {proxiesOption.length > 0 &&
                        <div className='section layout-style'>
                            <label className='fetch-sub-title one' for="proxies">Proxies<span class="required-field">*</span></label>
                            <div className='two'>
                                <select id="proxies" name="proxies" value={proxies} onChange={(e) => setInput(e)} multiple="multiple" required>
                                    <option value="" selected>select</option>
                                    {proxiesOption.map((item, i) => <option key={i} value={item}>{item}</option>)}
                                </select>
                            </div>
                        </div>
                    }
                </div>
                {visibleInfo &&
                    <div>
                        <div className='section layout-style'>
                            <label className='fetch-sub-title one' for="productid">Product ID<span class="required-field">*</span></label>
                            <div className='two'>
                                <input className='input-style' style={{ textTransform: "uppercase" }} type="text" id="productid" name="productid" value={productid} onChange={(e) => setInput(e)} pattern="[A-Za-z0-9]+" required oninput="this.value = this.value.toUpperCase()" />
                            </div>
                        </div>
                        <div className='section layout-style'>
                            <label className='fetch-sub-title one' for="name">Name<span class="required-field">*</span></label>
                            <div className='two'>
                                <input className='input-style' type="text" id="name" name="name" value={name} onChange={(e) => setInput(e)} required />
                            </div>
                        </div>
                        <div className='section layout-style'>
                            <label className='fetch-sub-title one' for="description">API Description</label>
                            <div className='two'>
                                <textarea className='custom-text-area-style' id="description" name="description" value={description} onChange={(e) => setInput(e)} rows="4" cols="50"></textarea>
                            </div>
                        </div>
                        <div className='section layout-style'>
                            <label className='fetch-sub-title one' for="imageUpload">Upload Images</label>
                            <div className='two'>
                                <input type="file" id="imageUpload" name="image_urls" onChange={(e) => setInput(e)} accept=".jpg, .jpeg, .png" multiple />
                            </div>
                        </div>
                        <div className='section layout-style'>
                            <label className='fetch-sub-title one' for="billingPeriodDays">Billing Period (Days):</label>
                            <div className='two'>
                                <input className='input-style' type="text" id="billingPeriodDays" name="billingPeriodDays" onChange={(e) => setInput(e)} value={billingPeriodDays} />
                            </div>
                        </div>

                        <div className='section layout-style'>
                            <label className='fetch-sub-title one' for="showInBucket">Quota: Free Hits per Month</label>
                            <div className='two'>
                                <input className='input-style' type="text" id="quota-amount" name="quotaAmount" onChange={(e) => setInput(e)} value={quotaAmount} />
                            </div>
                        </div>
                        <div className='section layout-style'>
                            <label className='fetch-sub-title one' for="onetimeSetupFee">One-time Setup Fee<span class="required-field">*</span>:</label>
                            <div className='two'>
                                <input className='input-style' type="text" id="onetimeSetupFee" name="onetimeSetupFee" onChange={(e) => setInput(e)} value={onetimeSetupFee} required />
                            </div>
                        </div>
                        <div className='section layout-style'>
                            <label className='fetch-sub-title one' for="recurringFee">Recurring Fee<span class="required-field">*</span>:</label>
                            <div className='two'>
                                <input className='input-style' type="text" id="recurringFee" name="recurringFee" value={recurringFee} onChange={(e) => setInput(e)} required />
                            </div>
                        </div>
                        <div className='section layout-style'>
                            <label className='fetch-sub-title one' for="ratePlanId">Rate Plan ID<span class="required-field">*</span>:</label>
                            <div className='two'>
                                <select id="ratePlanId" name="ratePlanId" value={ratePlanId} onChange={(e) => setInput(e)} multiple="multiple" required>
                                    <option value="" selected>select</option>
                                    <option value="RP1">RP1</option>
                                    <option value="RP2">RP2</option>
                                    <option value="RP3">RP3</option>
                                    <option value="NewRP">NewRP</option>
                                    <option value="SMSINTRP">SMSINTRP</option>
                                </select>
                            </div>
                        </div>
                        <div className='section layout-style'>
                            <label className='fetch-sub-title one' for="currency">Currency<span class="required-field">*</span>:</label>
                            <div className='two'>
                                <input className='input-style' type="text" id="currency" name="currency" value={currency} onChange={(e) => setInput(e)} required />
                            </div>
                        </div>

                        <div className='section layout-style'>
                            <label className='fetch-sub-title one' for="publisherPer">Publisher Percentage<span class="required-field">*</span>:</label>
                            <div className='two'>
                                <input className='input-style' type="text" id="publisherPer" name="publisherPer" value={publisherPer} onChange={(e) => setInput(e)} required />
                            </div>
                        </div>
                        <div className='section layout-style'>
                            <label className='fetch-sub-title one' for="agentPer">Agent Percentage<span class="required-field">*</span>:</label>
                            <div className='two'>
                                <input className='input-style' type="text" id="agentPer" name="agentPer" value={agentPer} onChange={(e) => setInput(e)} required />
                            </div>
                        </div>
                        <div className='section layout-style'>
                            <label className='fetch-sub-title one' for="hostPer">Host Percentage<span class="required-field">*</span>:</label>
                            <div className='two'>
                                <input className='input-style' type="text" id="hostPer" name="hostPer" value={hostPer} onChange={(e) => setInput(e)} required />
                            </div>
                        </div>
                        <div className='section layout-style'>
                            <label className='fetch-sub-title one' for="hostingFeePM">Hosting Fee PM<span class="required-field">*</span>:</label>
                            <div className='two'>
                                <input className='input-style' type="text" id="hostingFeePM" name="hostingFeePM" value={hostingFeePM} onChange={(e) => setInput(e)} required />
                            </div>
                        </div>
                        <div className='section layout-style'>
                            <label className='fetch-sub-title one' for="countries">Countries<span class="required-field">*</span>:</label>
                            <div className='two'>
                                <select id="countries" name="countries" value={countries} onChange={(e) => setInput(e)} multiple="multiple" required>
                                    <option value="IN" selected>IN</option>
                                    <option value="US" selected>US</option>
                                    <option value="UK">UK</option>
                                    <option value="SA">SA</option>
                                    <option value="DE">DE</option>
                                </select>
                            </div>
                        </div>

                        <div className='section layout-style'>
                            <label className='fetch-sub-title one' for="category">API Category<span class="required-field">*</span>:</label>
                            <div className='two'>
                                <select id="category" name="category" value={category} onChange={(e) => setInput(e)} required>
                                    <option value="communicationapi">Communication API</option>
                                    <option value="network">Network</option>
                                    <option value="data-science">Data Science</option>
                                    <option value="data-science">Finance</option>
                                </select>
                            </div>
                        </div>
                        <div className='section layout-style'>
                            <label className='fetch-sub-title one' for="category">Industry Categories<span class="required-field">*</span>:</label>
                            <div className='two'>
                                <select id="category" name="industry_categories" value={industry_categories} onChange={(e) => setInput(e)} multiple required>
                                    <option value="Health">Health</option>
                                    <option value="Retail">Retail</option>
                                    <option value="Tourism">Tourism</option>
                                </select>
                            </div>
                        </div>
                        <div className='section layout-style'>
                            <label className='fetch-sub-title one' for="startDate">Start Date<span class="required-field">*</span>:</label>

                            <div className='two'>
                                <input className='input-style' type="datetime-local" id="startDate" name="startDate" value={startDate} onChange={(e) => setInput(e)} required />

                            </div>
                        </div>
                        <div className='section layout-style'>
                            <label className='fetch-sub-title one' for="endDate">End Date<span class="required-field">*</span>:</label>
                            <div className='two'>
                                <input className='input-style' type="datetime-local" id="endDate" name="endDate" value={endDate} defaultValue="2034-12-31T23:59" onChange={(e) => setInput(e)} required />
                            </div>
                        </div>
                        {/* <label for="showInBucket">Show in Bucket<span class="required-field">*</span>:</label>
            <input type="text" id="showInBucket" name="showInBucket" value={showInBucket} onChange={(e) => setInput(e)} required />
            <br /><br /> */}
                        <br /><br />
                        <label className='main-title'>CDR Configuration:-</label>
                        <br /><br />

                        <div className='section layout-style'>
                            <label className='fetch-sub-title one' for="showInBucket">Charging Characterics:</label>
                            <div className='two'>
                                <input className='input-style' type="text" id="chargingCharacteristics" name="chargingCharacteristics" value={chargingCharacteristics} onChange={(e) => setInput(e)} />
                            </div>
                        </div>
                        <div className='section layout-style'>
                            <label className='fetch-sub-title one' for="showInBucket">Service Code</label>
                            <div className='two'>
                                <input className='input-style' type="text" id="teleserviceCode" name="teleserviceCode" value={teleserviceCode} onChange={(e) => setInput(e)} />
                            </div>
                        </div>
                        <div className='section layout-style'>
                            <label className='fetch-sub-title one' for="showInBucket">Bearer Service Code</label>
                            <div className='two'>
                                <input className='input-style' type="text" id="bearerServiceCode" name="bearerServiceCode" value={bearerServiceCode} onChange={(e) => setInput(e)} />
                            </div>
                        </div>
                        <div className='section layout-style'>
                            <label className='fetch-sub-title one' for="showInBucket">Service Type</label>
                            <div className='two'>
                                <input className='input-style' type="text" id="serviceType" name="serviceType" value={serviceType} onChange={(e) => setInput(e)} />
                            </div>
                        </div>
                        <div className='section layout-style'>
                            <label className='fetch-sub-title one' for="showInBucket">Service ID</label>
                            <div className='two'>
                                <input className='input-style' type="text" id="serviceId" name="serviceId" value={serviceId} onChange={(e) => setInput(e)} />
                            </div>
                        </div>
                        <div className='section layout-style'>
                            <button className='add-button' type="submit" onClick={() => onSubmit()}>Create API Product</button>
                        </div>
                    </div>
                }
            </div>
        </React.Fragment>
    )
}

export default CreateForm;